import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable  } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class OidcService  extends BaseService {
  access_token: string;
  constructor(public http: HttpClient) {
    super();
  }

  getToken() : Observable<any>
  {
    let body = 'scope=wwcApi&grant_type=client_credentials&client_secret=secret&response_type=id_token%2Btoken%0A';
    //const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic d3djYjJjOnNlY3JldA=='  });
    return this.http.post<any>(this.oidUrl + 'connect/token', body, {headers: headers});
    //console.log(response);
    //return response;
  }
}

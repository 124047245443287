/*export const environment = {
  production: true,
  apiUrl: 'https://devapi.worldwidecampers.com/api',
  apiUrlImage: 'https://devapi.worldwidecampers.com',
  apiUsersUrl: 'https://devidp.worldwidecampers.com/api',
  apiReports: 'http://reportservices.worldwidecampers.com/WWC/ExportReservation',
  resetUrl: 'https://devidp.worldwidecampers.com/account',
  oidUrl: 'https://devidp.worldwidecampers.com/',
  openIdConnectSettings: {
    authority: 'https://devidp.worldwidecampers.com/',
    client_id: 'tourmanagementclient',
    redirect_uri: 'https://devbackend.worldwidecampers.com/signin-oidc',
   //redirect_uri: 'https://pland.vuelandia.com/signin-oidc',
    scope: 'openid profile roles wwcApi',
    response_type: 'id_token token',
    post_logout_redirect_uri: 'https://devbackend.worldwidecampers.com/',
    //post_logout_redirect_uri: 'https://pland.vuelandia.com/',
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://devbackend.worldwidecampers.com/redirect-silentrenew'
    //silent_redirect_uri: 'https://pland.vuelandia.com/redirect-silentrenew'
  }
};*/

export const environment = {
  production: true,
  apiUrl: 'https://devapi.worldwidecampers.com/api',
  apiUrlImage: 'https://devapi.worldwidecampers.com',
  apiUsersUrl: 'https://idp.worldwidecampers.com/api',
  apiReports: 'http://reportservices.worldwidecampers.com/WWC/ExportReservation',
  resetUrl: 'https://idp.worldwidecampers.com/account',
  oidUrl: 'https://idp.worldwidecampers.com/',
  clientUrl: 'https://wwcstaging.websteen.nl/',
  apiUrlPayment: 1,
  openIdConnectSettings: {
    authority: 'https://idp.worldwidecampers.com/',
    client_id: 'b2cpartner',
    redirect_uri: 'https://devb2c.worldwidecampers.com/signin-oidc',
   //redirect_uri: 'https://pland.vuelandia.com/signin-oidc',
    scope: 'openid profile roles wwcApi',
    response_type: 'id_token token',
    post_logout_redirect_uri: 'https://devb2c.worldwidecampers.com/',
    //post_logout_redirect_uri: 'https://pland.vuelandia.com/',
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://devb2c.worldwidecampers.com/redirect-silentrenew'
    //silent_redirect_uri: 'https://pland.vuelandia.com/redirect-silentrenew'
  }
};

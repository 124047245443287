import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  public activeLang = 'en-us';
  constructor(
    private _translate: TranslateService,
    private _activatedRoute : ActivatedRoute
  ) {
    this._translate.setDefaultLang(this.activeLang);
  }
  ngOnInit() {
    this._activatedRoute.params.subscribe( (params : Params) => {
      this._translate.use( params['lang'] );
    });   
  }
  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this._translate.use(lang);
  }
}
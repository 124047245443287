import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private _translateService: TranslateService,
    private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this._translateService.instant('search.titleHome'));
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslationComponent } from './booking/translation/translation.component';
import { HomeComponent } from './booking/partner/home/home.component';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { RequireAuthenticatedUserRouteGuard } from './shared/require-authenticated-user-route.guard';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';

const routes: Routes = [
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'redirect-silentrenew', component: RedirectSilentRenewComponent },
  {
    path: 'home', component: HomeComponent,
    canActivate: [RequireAuthenticatedUserRouteGuard]
  },
  {
    path: '',
    redirectTo: 'en-us/search', pathMatch: 'full'
  },
  {
    path: ':lang',
    component: TranslationComponent, canActivate: [RequireAuthenticatedUserRouteGuard],
    data: {breadcrumb: 'Home'},
   
    children: [
      {  path: '', data: {breadcrumb: 'Booking'},
         loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)

      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { environment as environmentProd }  from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  oidUrl = environment.oidUrl;
  apiUrl = environment.apiUrl;
  apiImageUrl = environment.apiUrlImage;
  production = environment.production;
  clientUrl = environment.clientUrl;
  apiUrlPayment = environment.apiUrlPayment;
  constructor() { }
}

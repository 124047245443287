import { Injectable } from '@angular/core';
import {  HttpClient,  HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Country, City, BookingEngine, CamperPropertiesListDto, Genders, Reservation, Languages, ExchangeRates, Partner, Payment } from '../models/booking-engine.model';
import { BookingOptions } from '../models/booking-options.model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import localeNl from '@angular/common/locales/nl';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeAu from '@angular/common/locales/en-AU';
import localeDe from '@angular/common/locales/de';
import { CheckDiscountCode, DiscountCode } from '../models/discount-code.model';
declare var googleTagManager: any;

@Injectable({
  providedIn: 'root'
})
export class BookingEngineService extends BaseService {
  public _locale: string;
  constructor(public http: HttpClient, private _router: Router,
    private translate: TranslateService) {
    super();
    this._locale = this.getLanguage();
    console.log('language: ', this._locale);
    this.registerCulture();
  }
  getLanguage(): string {
    var languagesImported: Array<string> = ['en-us', 'es', 'nl', 'it', 'fr', 'en-au', 'de'];
    let language;
    language = window.location.pathname.toString();

    let languageSelected = language.split('/')[1];
    if(languageSelected.includes('_')){
      languageSelected = languageSelected.replace('_', '-');
    }

    if (languageSelected == null || languageSelected == undefined) {
      languageSelected = 'en-us';
    } else if(languagesImported.indexOf(languageSelected) == -1) {
      languageSelected = 'en-us';
    }
    return languageSelected;
  }
  /*set locale(value: string) {
    this._locale = value;
  }*/
  get locale(): string {
    return this._locale;
  }
  registerCulture() {
    registerLocaleData(localeEs); // we need this locale to format the numbers in euro (WSD-187)
    switch (this.locale) {
      case 'en-us': {
        registerLocaleData(localeEn);
        break;
      }
      /*case 'es': {
        registerLocaleData(localeEs);
        break;
      }*/
      case 'nl': {
        registerLocaleData(localeNl);
        break;
      }
      case 'it': {
        registerLocaleData(localeIt);
        break;
      }
      case 'fr': {
        registerLocaleData(localeFr);
        break;
      }
      case 'en-au': {
        registerLocaleData(localeAu);
        break;
      }
      case 'de': {
        registerLocaleData(localeDe);
        break;
      }
      default: {
        registerLocaleData(localeEn);
        break;
      }
    }
  }

  getCountriesList(language: string): Observable < Array < Country >> {
    return this.http.get < Array < Country >> (this.apiUrl + '/priceCalculator/countries/' + language);
  }
  getCitiesList(countryId: number, isDropoffCountry: boolean, changedDropoffCountry: boolean, language: string): Observable < Array < City >> {
    return this.http.get < Array < City >> (this.apiUrl + '/priceCalculator/cities/' + countryId + '/' + isDropoffCountry + '/' + changedDropoffCountry + '/' + language);
  }
  priceCalculator(countryId: number, dropoffCountryId: number, pickUpLocationId: number, dropOffLocationId: number, from: string, to: string, adults: number, children: number, exchangeRate: string): Observable < Array < BookingEngine >> {
    console.log(' - Booking Engine Start - ');
    return this.http.get < Array < BookingEngine >> (this.apiUrl + '/bookingEngine/search/' + countryId + '/' + pickUpLocationId +
      '/' + dropOffLocationId + '/' + from + '/' + to + '/' + adults + '/' + children + '/' + exchangeRate+ '/' + dropoffCountryId);
  }
  getBookingOptions(id: number, countryId: number, orderBy: string, reverse: boolean, convertCurrency: boolean, exchangeRateId: number, calculateDiscounts: boolean, bookingDate: string, pickupDate: string): Observable<Array<BookingOptions>>{
    return this.http.get < Array < BookingOptions >> (this.apiUrl + '/bookingOptions/list/' + id + '?OrderBy=' + orderBy + '&Reverse=' + reverse + '&CountryId=' + countryId + '&ConvertCurrency=' + convertCurrency + '&ExchangeRateId=' + exchangeRateId + "&CalculateDiscounts="+calculateDiscounts+ "&BookingDate="+bookingDate+"&PickupDate="+pickupDate);
  }
  getExchangeRateByCode(code: string): Observable < ExchangeRates > {
    return this.http.get < ExchangeRates > (this.apiUrl + '/ExchangeRates/getByCode/' + code);
  }
  getAllCamperPropByCat(camperId: number): Observable < Array < CamperPropertiesListDto >> {
    return this.http.get < Array < CamperPropertiesListDto >> (this.apiUrl + '/bookingEngine/AllCamperPropByCat/' + camperId);
  }
  getGendersList(): Observable < Array < Genders >> {
    return this.http.get < Array < Genders >> (this.apiUrl + '/reservations/genderList/');
  }
  getPayment(payment: Payment): Observable < string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post < string > (this.apiUrl + '/Payment/getPayment/', payment, {
      headers: headers
    });
  }
  getStatusOfPayment(reservationNumber: number): Observable <any> {
    return this.http.get <any> (this.apiUrl + '/Payment/GetStatusOfPayment/' + reservationNumber);
  }
  getReservation(reservationNumber: number): Observable <Reservation> {
    return this.http.get <Reservation> (this.apiUrl + '/Payment/GetReservation/' + reservationNumber);
  }
  updateReservationPayment(id: string): Observable<Reservation>{
    return this.http.get<Reservation>(this.apiUrl + '/Payment/UpdateReservationPayment/' + id);
  }
  saveBooking(reservation: Reservation): Observable < Reservation > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post < Reservation > (this.apiUrl + '/reservations', reservation, {
      headers: headers
    });
  }

  getCountry(id: number, languageId: number): Observable < Country > {
    return this.http.get < Country > (this.apiUrl + '/priceCalculator/country/' + id+ '/' + languageId);
  }
  goTo(url: string, supplier?: string, camper?: string) {
    let lang = this.getLanguage();
    /* CLIENT URL */
    //let clientUrl = 'https://worldwidecampers.com/';
    let clientUrl = this.clientUrl;
    if(lang.includes('_')){
      lang = lang.replace('_', '-');
    }
    let step1 = lang + '/booking/';
    /* END CLIENT URL */

    /*let iframe = window.self !== window.top;

    if (iframe && (url == 'step1')) { // !this.production
      if (url == 'step1') window.open(clientUrl + step1, '_top');
    } else */if(url == 'camper-details'){
      let camperdetails = lang + '/suppliers/';
      if(lang == 'en-us'){
        camperdetails = 'en-us/suppliers/';
      } else if(lang == 'en-au'){
        camperdetails = 'en-au/suppliers/';
      } else if(lang == 'it'){
        camperdetails = 'it/fornitori-di-noleggio-camper/';
      } else if(lang == 'nl'){
        camperdetails = 'camperverhuurders/';
      } else if(lang == 'fr'){
        camperdetails = 'fr/agences/';
      } else if(lang == 'es'){
        camperdetails = 'es/proveedores-de-autocaravanas/';
      } else if(lang == 'de'){
        camperdetails = 'de/wohnmobilanbietern/';
      }
      let path = clientUrl + camperdetails + supplier + '/' + camper + '/';
      googleTagManager.setPage(path);
      window.open(path, 'popup','width=1000,height=800');
    } else {
      this._router.navigate(['/' + lang + '/' + url]);
    }
  }
  setFormGTM(price: number, currencyCode: string, reservationNumber: string){
    googleTagManager.setForm(price, currencyCode, reservationNumber);
  }
  getLanguages():Observable<Array<Languages>> {
    return this.http.get<Array<Languages>>(this.apiUrl + '/translations/languages');
  }
  getExchangeRates(): Observable<Array<ExchangeRates>> {
    return this.http.get<Array<ExchangeRates>>(this.apiUrl + '/exchangeRates/visible');
  }
  getCurrentUser(userName: string): Observable < any > {
    return this.http.get < any > (this.apiUrl + '/Users/ByUsername/' + userName);
  }
  roundToHalf05(value: number) {
    if(value % 1 != 0){
      var numarray=value.toString().split('.');
      if(Number(numarray[1].charAt(0)) > 5){
        let n = Number(numarray[0])+1;
        //console.log('round', value, ' to', n);
        return n;
      } else if(Number(numarray[1].charAt(0))< 5){
        let n = Number(numarray[0])+0.5;
        //console.log('round', value, ' to', n);
        return n;
      } else if(Number(numarray[1].charAt(0)) ==  5 && (numarray[1].length > 1 && Number(numarray[1].charAt(1)) > 0)){
        let n = Number(numarray[0])+1;
        //console.log('round', value, ' to', n);
        return n;
      } else {
        return  Number(value);
      }
    } else {
      return value;
    }
 }
  getAllBookingsByPartner(id: number): Observable<Array<Reservation>>{
    return this.http.get<Array<Reservation>>(this.apiUrl + '/reservations/GetAllByPartner/' + id);
  }
  getAllBookingsByUser(id: string): Observable<Array<Reservation>>{
    return this.http.get<Array<Reservation>>(this.apiUrl + '/reservations/GetReservationsByUser/' + id);
  }
  getPartnersByUser(id: string): Observable<Partner>{
    return this.http.get<Partner>(this.apiUrl + '/reservations/GetPartnerByUser/' + id);
  }
  getPartnerReservationById(reservationId: number, partnerId: number): Observable<Reservation>{
    return this.http.get<Reservation>(this.apiUrl + '/reservations/GetPartnerReservationById/' + reservationId + '/' + partnerId);
  }
  applyDiscountCode(checkDiscountCode: CheckDiscountCode): Observable<DiscountCode>{
    return this.http.post<DiscountCode>(this.apiUrl + '/DiscountCode/ApplyDiscountCode', checkDiscountCode);
  }

}

import { Injectable } from '@angular/core';
import {UserManager, User, Log} from 'oidc-client';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { Usuario } from './models/booking-engine.model';
import { BookingEngineService } from './services/booking-engine.service';

@Injectable({
  providedIn: 'root'
})
export class OpenIdConnectService {

  private userManager: UserManager = new UserManager(environment.openIdConnectSettings);
 
  private currentUser: User;
  public usuario: Usuario;
 
  userLoaded$ = new ReplaySubject<boolean>(1);  // observerable rxjs
 
  get userAvailable(): boolean {
    return this.currentUser != null;
  }
 
  get user(): User {
    return this.currentUser;
  }
 
  constructor(private _bookingService: BookingEngineService,) {
    this.userManager.clearStaleState();
 
    this.userManager.events.addUserLoaded(user => {
      if (!environment.production) {
        console.log('User loaded.', user);
      }
      this.currentUser = user;
      this._bookingService.getCurrentUser(user.profile?.name).subscribe(data => {
        this.usuario = data;
        console.log('Usuario cargado: ', this.usuario);
      })
      this.userLoaded$.next(true); // broadcast
    });
 
    this.userManager.events.addUserUnloaded(() => {
      if (!environment.production) {
        console.log('User unloaded.');
      }
      this.currentUser = null;
      this.userLoaded$.next(false);
    });
  }
 
  triggerSignIn() {
    this.userManager.signinRedirect().then(() => {
      if (!environment.production) {
        console.log('Redirction to signin triggered');
      }
    });
  }
 
  handleCallback() {
    this.userManager.signinRedirectCallback().then(user => {
      if (!environment.production) {
        console.log('callback after signin handled.', user);
      }
    });
  }
 
  handleSilentCallback() {
    this.userManager.signinSilentCallback().then(user => {
      this.currentUser = user;
      if (!environment.production) {
        console.log('callback after silent signin handled.', user);
      }
    });
  }
 
  triggerSignOut() {
    this.userManager.signoutRedirect().then(resp => {
      let storageName = 'oidc.user:'+environment.openIdConnectSettings.authority+':'+environment.openIdConnectSettings.client_id;
      localStorage.removeItem(storageName);
      sessionStorage.removeItem(storageName);
      if (!environment.production) {
        console.log('redirection to sign out triggered', resp);
      }
    });
  }
}

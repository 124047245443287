import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { OpenIdConnectService } from "./open-id-connect.service";
import { environment } from '../../environments/environment';

@Injectable()
export class RequireAuthenticatedUserRouteGuard implements CanActivate {
 
  constructor(
    private openIdConnectService: OpenIdConnectService,
    private router: Router
  ) { }
 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
      let storageName = 'oidc.user:'+environment.openIdConnectSettings.authority+':'+environment.openIdConnectSettings.client_id;
      //console.log('storageName', storageName);
    if (this.openIdConnectService.userAvailable) {
      localStorage.setItem(storageName, JSON.stringify(this.openIdConnectService.user));
      return true;
    } else {
      //console.log('url', window.location.pathname);
      if(window.location.pathname.endsWith("/home") || window.location.pathname.includes("/bookings")){
        // trigger signin
        this.openIdConnectService.triggerSignIn();
        return false;
      }
      //console.log('sessionStorage', Object.keys(sessionStorage));

      let isLogged: boolean = false;
      Object.keys(sessionStorage).forEach((key) => {
        if(key == storageName){
          //console.log('esta registrado');
          isLogged = true;
        }
      });
      if(!isLogged){
        Object.keys(localStorage).forEach((key) => {
          if(key == storageName){
            //console.log('esta registrado');
            isLogged = true;
            sessionStorage.setItem(storageName, localStorage[key]);
          }
        });
      } else {
        localStorage.removeItem(storageName);
        sessionStorage.removeItem(storageName);
      }
      if(!isLogged){
        return true;
      } 
      else {
        // trigger signin
        this.openIdConnectService.triggerSignIn();
        return false;
      }

    }
  }
}
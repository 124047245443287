import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
declare var googleTagManager: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'frontend-b2c';
  constructor(router:Router) {
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        googleTagManager.setPage(window.location.href);
      }
    });
  }
ngOnInit() {
  if (environment.production) {
  if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
     }
  }
}
}
import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent }
     from "@angular/common/http";

import { OidcService } from "./services/oidc.service";
import { Observable } from "rxjs";
import { OpenIdConnectService } from "./open-id-connect.service";

@Injectable()
export class AddAuthorizationHeaderInterceptor implements HttpInterceptor {
    constructor(private openIdConnectService: OidcService, public openIdConnectServiceLoginIdp: OpenIdConnectService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler):
         Observable<HttpEvent<any>> {
        // add the access token as bearer token
        if(!request.url.includes('connect/token'))
        {    
            var access_token = "";
            if(this.openIdConnectServiceLoginIdp != null && this.openIdConnectServiceLoginIdp.userAvailable){
                console.log('Conexión logueado');
                access_token = this.openIdConnectServiceLoginIdp.user.access_token;
            } else {
                console.log('Conexión sin loguear');
                access_token = this.openIdConnectService.access_token;
            }
            request = request.clone(
            { setHeaders: { Authorization: 'Bearer' 
                + " " + access_token } });
        }
        return next.handle(request);
    }
}
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddAuthorizationHeaderInterceptor } from './shared/add-authorization-header-interceptor';
import { OidcService } from './shared/services/oidc.service';

import { BookingEngineService } from './shared/services/booking-engine.service';

import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { OpenIdConnectService } from './shared/open-id-connect.service';
import { RequireAuthenticatedUserRouteGuard } from './shared/require-authenticated-user-route.guard';

@NgModule({
  declarations: [
    AppComponent,
    SigninOidcComponent,
    RedirectSilentRenewComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    BrowserAnimationsModule
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AddAuthorizationHeaderInterceptor,
    multi: true
  },OidcService
  ,{provide: LOCALE_ID,
    deps: [BookingEngineService],
    useFactory: (BookingEngineService: { locale: string; }) => BookingEngineService.locale
   },
  //, { provide: LOCALE_ID, useValue: 'fr' }
  OpenIdConnectService,
  RequireAuthenticatedUserRouteGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}